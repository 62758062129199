<template>
  <b-modal
    id="setContactModal"
    ref="setContactModal"
    centered
    :title="title"
    hide-footer
    no-close-on-backdrop
  >
    <validation-observer ref="set-contact-form">
      <b-row
        no-gutters
      >
        <b-col
          cols="12"
          lg="6"
        >
          <validation-provider
            #default="{ errors }"
            name="Tipo di Contatto"
            rules="required"
          >
            <b-form-group
              :label="'Tipo di Contatto'"
              label-class="font-weight-bolder"
            >
              <v-select
                v-model="contactToSet.tipo_contatto"
                class="mr-lg-1"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                filterable
                :options="optionContacts"
                placeholder="Tipo di Contatto"
              />
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <validation-provider
            #default="{ errors }"
            name="Tipo di Contatto"
            rules="required"
          >
            <b-form-group
              :label="'Indirizzo'"
              label-class="font-weight-bolder"
            >
              <b-form-input
                v-model="contactToSet.valore"
                placeholder="valore"
              />
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
    <b-button
      variant="primary"
      @click="addContact"
    >Salva</b-button>
    <!-- {{ idRecapito }}
    <br>
    {{ contact }}
    <br>
    contactToSet {{ contactToSet }} -->
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal, BFormGroup, BFormInput, BCol, BRow, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from 'axios'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    vSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    idRecapito: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: 'Aggiungi Contatto',
    },
    contactToSet: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionContacts: ['Tel', 'Pec', 'Email', 'Website'],
      /* { value: 'tel', text: 'Tel', selected: true },
        { value: 'pec', text: 'Pec' },
        { value: 'email', text: 'Email' },
        { value: 'website', text: 'SitoWeb' },
      ], */
      valueContact: '',
      contact: {
        typeContact: {},
        valore: '',
      },
    }
  },
  computed: {
    contactComputed() {
      // console.log(this.contactToSet)
      return this.contactToSet
    },
  },
  mounted() {
    // console.log('ho creato la modal')
  },
  methods: {
    async addContact() {
      this.$refs['set-contact-form'].validate().then(async success => {
        if (success) {
          let data = {}
          if (this.title === 'Modifica Contatto') {
            data = {
              id_recapito: this.idRecapito,
              tipo_contatto: this.contactToSet.tipo_contatto,
              valore: this.contactToSet.valore,
              id_contatto: this.contactToSet.id,
            }
          } else {
            data = {
              id_recapito: this.idRecapito,
              tipo_contatto: this.contactToSet.tipo_contatto,
              valore: this.contactToSet.valore,
            }
          }
          // console.log('data addContact', data)
          await axios.post(process.env.VUE_APP_URL_API_SERVER, data,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Persona',
                metodo: 'setContatto',
                ACL: 'invoice-list',
              },
            }).then(res => {
            // console.log(res)
            if (res.data.esito === 'OK') {
              const idRecapito = res.data.contatti.id_recapito
              const idContact = res.data.contatti.id
              // console.log(this.$store.state.profile.address)
              const findAddress = this.$store.state.profile.address.findIndex(el => el.id === idRecapito)
              // console.log('findContact', findAddress)
              const indexContact = this.$store.state.profile.address[findAddress].contatti.findIndex(el => el.id === idContact)
              // se trova il contatto
              if (indexContact >= 0) {
                // console.log('index > 0', indexContact)
                this.$store.state.profile.address[findAddress].contatti.splice(indexContact, 0)
                this.$store.state.profile.address[findAddress].contatti[indexContact] = {
                  id_recapito: res.data.contatti.id_recapito,
                  tipo_contatto: res.data.contatti.tipo_contatto,
                  valore: res.data.contatti.valore,
                  id_contatto: res.data.contatti.id,
                }
                // console.log('dopo spdread', this.$store.state.profile.address[findAddress].contatti[indexContact])
                this.alertSuccess()
              } else {
                // se non trova il contatto
                // console.log('indexContact < 0', indexContact)
                this.$store.state.profile.address[findAddress].contatti.unshift(res.data.contatti)
                this.alertSuccess()
              }
            }
          }).catch(e => e)
          this.$refs.setContactModal.hide()
        }
      })
    },
    alertSuccess() {
      this.$swal({
        title: 'Salvataggio Effettuato Correttamente',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    // this.$refs.addaddress.hide()
    // e.preventDefault()
    // this.$refs.addaddress.hide()
    // this.$root.$on('bv::modal::hide', 'addaddress')
    // this.$refs.addaddress.hide()
  },
}
</script>

<style>

</style>
