/*
  filterPreferences prende in input tutte le preferenze e quelle selezionate.
  Quelle selezionate vengono intese come spuntate 'Y'.
  Quindi mappo tutti i codici(univoci), questi codici che sono delle stringhe univoche dei valori Y.
  ritorno un array mappato con codice e valore Y o N in base se sono presenti nei codici univoci selezionati
*/
export default function filterPreferences(preferences, selectedPreferences) {
  const valueSelected = selectedPreferences.map(pref => pref.codice)
  return preferences.map(pref => ({ codice: pref.codice, valore: valueSelected.includes(pref.codice) ? 'Y' : 'N' }))
}
