<template>
  <div>
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-card>
        <b-row>
          <b-form
            @submit.prevent="submitPreferences"
          >
            <!-- <b-col
              v-for="(preference, index) in optionsLocalFor"
              :key="preference.codice + index"
              cols="12"
              class="mb-2"
            >
              <b-form-checkbox
                :id="preference.codice"
                v-model="optionsLocalFor[index].valore"
                value="Y"
                :disabled="preference.codice ==='TERMINI_E_CONDIZIONI'"
                unchecked-value="N"
                :name="preference.codice"
                switch
                inline
                @change="stampare"
              >
                <span>{{ preference.testo }}</span>
              </b-form-checkbox>
            </b-col> -->
            <b-form-checkbox-group
              v-model="preferencesSelected"
              :options="preferences"
              name="preferences-user"
              text-field="testo"
              value-field="valore"
              switches
              stacked
              class="ml-1"
            />
            <!-- buttons -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1 mt-1"
                type="submit"
              >
                {{ $t('personalProfile.notification.saveButton') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mt-1"
                variant="outline-secondary"
                @click.prevent="resetForm"
              >
                {{ $t('personalProfile.notification.resetButton') }}
              </b-button>
            </b-col>
            <!--         {{ this.$store.state.profile.pincopallo }}
            <hr>
            {{ this.$store.state.profile.preferencesFor }} -->
            <!-- <hr>
            {{ this.$store.state.profile.preferences }}
            <hr>
            {{ this.$store.state.profile.resetPreferences }}
            <hr>
            {{ this.$store.state.profile.selectedPreferences }} -->
            <!--/ buttons -->
          </b-form>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckboxGroup, BForm, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import isEqual from 'lodash'
import filterPreferences from './utils'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    // BFormCheckbox,
    BForm,
    BFormCheckboxGroup,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    /* notificationData: {
      type: Array,
      default: () => [],
    },
    notificationDataFor: {
      type: Array,
      default: () => [],
    }, */
  },
  data() {
    return {
      optionToReset: [],
    }
  },
  computed: {
    preferencesSelected: {
      get() {
        // return JSON.parse(JSON.stringify(this.notificationData))
        return this.$store.state.profile.selectedPreferences
      },
      set(value) {
        // console.log('dentro set computed')
        this.$store.commit('profile/storeSelectedPreferences', value)
      },
    },
    preferences: {
      get() {
        return this.$store.state.profile.preferences
      },
      set(value) {
        this.$store.commit('profile/storePreferences', value)
      },
    },
  },
  created() {
    // this.notificationData = [...this.optionsLocal]
    // console.log(this.notificationData)
  },
  methods: {
    submitPreferences() {
      const preferencesToSend = filterPreferences(this.preferences, this.preferencesSelected)
      this.$emit('update-preferences', preferencesToSend)
    },
    resetForm() {
      this.$store.commit('profile/resetPreferencesOnReset')
    },
    somethingChangedBeforeChangeTab() {
      if (!isEqual(this.$store.state.profile.resetPreferences, this.$store.state.profile.selectedPreferences)) return true
      return false
    },
  },
}
</script>
