<template>
  <div>
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-card>
        <!-- form -->
        <validation-observer ref="changePassword">
          <b-form
            method="POST"
            @submit.prevent="validationForm"
          >
            <b-row>
              <!-- old password -->
              <b-col md="6">
                <b-form-group
                  :label="$t('personalProfile.changePassowrd.oldPassword')"
                  label-for="account-old-password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="PasswordOld"
                    rules="required|min:8"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-old-password"
                        v-model="passwordValueOld"
                        name="old-password"
                        :type="passwordFieldTypeOld"
                        autocomplete="current-password"
                        :placeholder="$t('personalProfile.changePassowrd.placeholder.oldPassword')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconOld"
                          class="cursor-pointer"
                          @click="togglePasswordOld"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--/ old password -->
            </b-row>
            <b-row>
              <!-- new password -->
              <b-col md="6">
                <b-form-group
                  label-for="account-new-password"
                  :label="$t('personalProfile.changePassowrd.newPassowrd')"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="Password"
                    rules="required|min:8|password"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-new-password"
                        v-model="newPasswordValue"
                        :type="passwordFieldTypeNew"
                        name="new-password"
                        autocomplete="new-password"
                        :placeholder="$t('personalProfile.changePassowrd.placeholder.newPassowrd')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconNew"
                          class="cursor-pointer"
                          @click="togglePasswordNew"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--/ new password -->

              <!-- retype password -->
              <b-col md="6">
                <b-form-group
                  label-for="account-retype-new-password"
                  :label="$t('personalProfile.changePassowrd.confirmNewPassowrd')"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="required|confirmed:Password"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-retype-new-password"
                        v-model="RetypePassword"
                        :type="passwordFieldTypeRetype"
                        name="retype-password"
                        autocomplete="new-password"
                        :placeholder="$t('personalProfile.changePassowrd.placeholder.confirmNewPassowrd')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconRetype"
                          class="cursor-pointer"
                          @click="togglePasswordRetype"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--/ retype password -->

              <!-- buttons -->
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1 mr-1"
                  type="submit"
                >
                  {{ $t('personalProfile.changePassowrd.saveButton') }}

                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  class="mt-1"
                  @click.prevent="resetForm"
                >
                  {{ $t('personalProfile.changePassowrd.resetButton') }}
                </b-button>
              </b-col>
              <!--/ buttons -->
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, password } from '@validations'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { filterErrorResponseApi } from '@core/utils/filter'

import axios from 'axios'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      errorsResponse: [],
      formEdited: false,
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required,
      password,
    }
  },
  computed: {
    localeNow() {
      return this.$i18n.locale
    },
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    passwordValueOld() {
      this.formEdited = true
      // console.log(this.passwordValueOld.length)
    },
    newPasswordValue() {
      this.formEdited = true
    },
    RetypePassword() {
      this.formEdited = true
    },
  },
  mounted() {
    localize(this.localeNow)
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    validationForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      // const token = `Bearer ${localStorage.getItem('token')}`
      const token = `Bearer ${sessionStorage.getItem('token')}`
      // console.log('token2', token2)
      // console.log('email', this.email)
      // console.log('password', this.password)
      // console.log('cpassword', this.cPassword)
      this.$refs.changePassword.validate().then(async success => {
        if (success) {
          await axios.post(process.env.VUE_APP_URL_API_SERVER, {
            current_password: this.passwordValueOld,
            new_password: this.newPasswordValue,
            confirm_password: this.RetypePassword,
          },
          {
            headers: {
              Authorization: token,
            },
          })
            .then(res => {
            // console.log('sei dentro lo store/resetPassword')
              // console.log(res)
              if (res.data.esito === 'OK') {
                this.$swal({
                  title: res.data.messaggio,
                  // text: 'Controlla la posta per confermare',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                this.resetForm()
              }
              if (res.data.esito === 'KO') {
                this.errorsResponse = filterErrorResponseApi(res.data.messaggio)
                // console.log('2', this.errorsResponse)
                this.$swal.fire({
                  title: '<strong><u>Errore</u></strong>',
                  icon: 'error',
                  html: this.errorsResponse.map(item => `<p class="text-left">${item}</p>`).join(''),
                  showCloseButton: true,
                  showCancelButton: true,
                  focusConfirm: false,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                })
              }
            })
            .catch(e => {
              // console.log(e)
              if (e) {
                this.$swal({
                  title: 'Errore',
                  text: 'Controlla i dati inseriti',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }
            })
        }
      })
    },
    somethingChangedBeforeChangeTab() {
      if (this.passwordValueOld !== '') return true
      if (this.newPasswordValue !== '') return true
      if (this.RetypePassword !== '') return true
      return false
    },
    resetForm() {
      this.$refs.changePassword.reset()
      this.passwordValueOld = ''
      this.newPasswordValue = ''
      this.RetypePassword = ''
      this.formEdited = false
    },
  },
}
</script>
