<template>
  <div>
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-card>
        <!-- <template #loading>
            <div class="d-flex justify-content-between">
              <b-col
                class="p-0 m-0 mb-2"
              >
                <div class="mb-1">
                  <b-skeleton-img
                    width="100px"
                    height="100px"
                    no-aspect
                  />
                </div>
                <div class="mb-1">
                  <b-skeleton
                    type="button"
                    height="20px"
                  />
                </div>
                <div class="mb-1">
                  <b-skeleton
                    width="30%"
                  />
                </div>
              </b-col>
              <b-col
                cols="4"
                md="3"
                lg="2"
                class="mb-2"
              >
                <b-skeleton width="100%" />
                <b-skeleton width="100%" />
                <b-skeleton width="100%" />
              </b-col>
            </div>
            <b-row>
              <b-col
                sm="6"
              >
                <div class="my-2">
                  <b-skeleton
                    type="input"
                    width="100%"
                    height="38px"
                  />
                </div>
              </b-col>
              <b-col
                sm="6"
              >
                <div class="my-2">
                  <b-skeleton
                    type="input"
                    width="100%"
                    height="38px"
                  />
                </div>
              </b-col>
              <b-col
                sm="6"
              >
                <div class="my-2">
                  <b-skeleton
                    type="input"
                    width="100%"
                    height="38px"
                  />
                </div>
              </b-col>
              <b-col
                sm="6"
              >
                <div class="my-2">
                  <b-skeleton
                    type="input"
                    width="100%"
                    height="38px"
                  />
                </div>
              </b-col>
              <b-col
                sm="6"
              >
                <div class="my-2">
                  <b-skeleton
                    type="input"
                    width="100%"
                    height="38px"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="d-flex">
                <div class="mr-2">
                  <b-skeleton
                    type="button"
                    width="65px"
                    height="25px"
                  />
                </div>
                <div>
                  <b-skeleton
                    type="button"
                    width="65px"
                    height="25px"
                  />
                </div>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-end mt-2">
              <b-skeleton
                width="150px"
                height="25px"
              />
            </div>
          </template> -->
        <b-col class="m-0 p-0 d-flex justify-content-between">
          <div>
            <b-img
              rounded
              :src="generalData.avatar"
              :width="'100px'"
              :height="'100px'"
            />
            <!--/ avatar -->
            <!-- upload button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75 mt-75 d-block"
              @click="$refs.refInputEl.$el.click()"
            >
              {{ $t('personalProfile.general.uploadButton') }}
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="profileFile"
              accept="image/*"
              :hidden="true"
              plain
              @input="profileFile !== null ? previewNewImage(profileFile) : ''"
            />
            <!-- <div><label>Allowed only <span>PNG, JPG, GIF, SVG</span></label></div> -->
            <b-alert
              :show="errorPreviewBase64"
              variant="warning"
              class="mb-50"
            >
              <div class="alert-body">
                Formato immagine Non valido
              </div>
            </b-alert>
          </div>
          <ul class="list-unstyled">
            <li class="d-flex justify-content-end font-weight-bolder">
              {{ generalData.ragione_sociale }}
            </li>
            <li class="d-flex justify-content-end font-weight-bolder">
              {{ generalData.email }}
            </li>
            <li class="d-flex justify-content-end font-weight-bolder">
              <span
                v-for="(ruolo, index) in generalData.ruolo"
                :key="index"
              >
                {{ ruolo.concat(' ') }}
              </span>
            </li>
          </ul>
        </b-col>
        <b-col
          cols="12"
          class="m-0 p-0"
        >
          <validation-observer ref="changeProfileSettingGeneral">
            <!-- form -->
            <b-form
              class="mt-2"
              @submit.prevent="validationForm"
            >
              <b-row>
                <b-col sm="5">
                  <b-form-group
                    :label="$t('personalProfile.general.name')"
                    label-for="profile-name"
                    label-class="font-weight-bolder"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nome"
                      rules="required"
                    >
                      <b-form-input
                        v-model="generalData.nome"
                        :placeholder="$t('personalProfile.general.placeholder.name')"
                        name="name-profile"
                      />
                      <small class="text-danger mt-25">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="5">
                  <b-form-group
                    :label="$t('personalProfile.general.surname')"
                    label-for="profile-surname"
                    label-class="font-weight-bolder"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Cognome"
                      rules="required"
                    >
                      <b-form-input
                        v-model="generalData.cognome"
                        name="surname"
                        :placeholder="$t('personalProfile.general.placeholder.surname')"
                      />
                      <small class="text-danger mt-25">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="2">
                  <validation-provider
                    #default="{ errors }"
                    name="Sesso"
                    rules="required"
                  >
                    <b-form-group
                      label="Sesso"
                      label-for="profle-gender"
                      label-class="font-weight-bolder"
                      class=""
                    >
                      <div
                        id="profle-gender"
                        class="demo-inline"
                      >
                        <b-form-radio
                          v-model="generalData.sesso"
                          name="male"
                          value="M"
                        > Uomo
                        </b-form-radio>
                        <b-form-radio
                          v-model="generalData.sesso"
                          name="female"
                          value="F"
                        > Donna
                        </b-form-radio>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="4"
                  xl="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nazionalità"
                    rules="required"
                  >
                    <b-form-group
                      label="Nazionalità"
                      label-for="collaborator-nation"
                      label-class="font-weight-bolder"
                      class=""
                    >
                      <div
                        id="collaborator-nation"
                        class="demo-inline"
                      >
                        <b-form-radio
                          v-model="generalData.stato_nascita"
                          name="italian"
                          value="IT"
                          @change="generalData.citta_nascita = ''"
                        > Italiana
                        </b-form-radio>
                        <b-form-radio
                          v-model="generalData.stato_nascita"
                          name="not-italian"
                          value="EE"
                          @change="generalData.citta_nascita = ''"
                        > Estera
                        </b-form-radio>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="8"
                  md="6"
                  lg="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Data di nascita"
                    rules="required"
                  >
                    <b-form-group
                      label="Data di Nascita"
                      label-for="profile-bornInformation-date"
                      label-class="font-weight-bolder"
                    >
                      <flat-pickr
                        id="profile-bornInformation-date"
                        v-model="generalData.data_nascita"
                        class="form-control"
                        name="birth-date"
                        :config="configFlatPicker"
                        :placeholder="$t('personalProfile.information.placeholder.dateBirth')"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="generalData.stato_nascita !== 'EE'"
                  cols="12"
                  md="4"
                  xl="3"
                >
                  <b-form-group
                    :label="'Prov di Nascita'"
                    label-for="anagrafic-city-born"
                    label-class="font-weight-bolder"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="'Prov di Nascita'"
                      rules="required"
                    >
                      <v-select
                        v-model="generalData.prov_nascita"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="prov"
                        :clearable="false"
                        taggable
                        :reduce="prov => prov.prov"
                        filterable
                        :options="province"
                        :create-option="comune => ({ prov: comune })"
                        @input="calculatefiscalcode2"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  xl="4"
                >
                  <b-form-group
                    :label="generalData.stato_nascita === 'EE' ? 'Nazione di Nascita' : 'Comune di Nascita'"
                    label-for="anagrafic-city-born2"
                    label-class="font-weight-bolder"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="generalData.stato_nascita === 'EE' ? 'Nazione di Nascita' : 'Comune di Nascita'"
                      rules="required"
                    >
                      <v-select
                        v-model="generalData.citta_nascita"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="comune"
                        :clearable="false"
                        filterable
                        taggable
                        :reduce="comune => comune.comune"
                        :options="comuniComp"
                        :create-option="comune => ({ comune: comune })"
                        @input="calculatefiscalcode2"
                      >
                        <!-- eslint-disable-next-line vue/no-unused-vars  -->
                        <template #no-options="{ search, searching }">
                          <div
                            v-if="generalData.stato_nascita === 'EE'"
                          >
                            Seleziona lo Stato
                          </div>
                          <div
                            v-if="generalData.stato_nascita !== 'EE'"
                          >
                            Seleziona la Provincia
                          </div>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="6"
                >
                  <b-form-group
                    :label="$t('personalProfile.general.fiscalCode')"
                    label-for="profile-fiscalCode"
                    label-class="font-weight-bolder"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Cod.Fiscale"
                      rules="required|length:16|regex:^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$"
                    >
                      <b-input-group>
                        <b-form-input
                          id="collaborator-anagrafic-fiscalCode"
                          v-model="generalData.codice_fiscale"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('personalProfile.general.placeholder.fiscalCode')"
                        />
                        <b-input-group-append>
                          <b-button
                            v-b-modal="'calculateFiscalCode'"
                            variant="primary"
                          >
                            <!-- @click="calculateFiscalCode" -->
                            Calcola
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <CalculateFiscalCode
                        @fiscalcode="addFiscalCode"
                      />
                      <small class="text-danger mt-25">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="$t('personalProfile.general.vat')"
                    label-for="profile-vat"
                    label-class="font-weight-bolder"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="P.IVA"
                      rules=""
                    >
                      <b-form-input
                        v-model="generalData.partita_iva"
                        name="vat"
                        :placeholder="$t('personalProfile.general.placeholder.vat')"
                      />
                      <small class="text-danger mt-25">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group
                    :label="'Codice SDI'"
                    label-for="profile-SDI"
                    label-class="font-weight-bolder"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="codice SDI"
                      rules="required"
                    >
                      <b-form-input
                        v-model="generalData.codice_sdi"
                        :placeholder="$t('personalProfile.general.placeholder.telephone')"
                      />
                      <small class="text-danger mt-25">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group
                    :label="$t('personalProfile.general.telephone')"
                    label-for="profile-telephone"
                    label-class="font-weight-bolder"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Telefono"
                      rules="required"
                    >
                      <b-form-input
                        v-model="generalData.cellulare"
                        name="telephone"
                        :placeholder="$t('personalProfile.general.placeholder.telephone')"
                      />
                      <small class="text-danger mt-25">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-2 mr-1"
                    type="submit"
                  >
                    {{ $t('personalProfile.general.saveButton') }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    type="reset"
                    class="mt-2"
                    @click.prevent="resetForm"
                  >
                    {{ $t('personalProfile.general.resetButton') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
        <!--/ media -->
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BImg, BAlert, BOverlay, BInputGroupAppend, BInputGroup, BFormRadio,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import CodiceFiscale from '@core/fiscalcode/dist/codice.fiscale.amd'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Italian } from 'flatpickr/dist/l10n/it'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer, blobToBase64 } from '@core/comp-functions/forms/form-utils'
import isEqual from 'lodash'
import { COMUNI } from '@core/fiscalcode/lists/lista-comuni'
import { PROVINCE } from '@core/fiscalcode/lists/lista-province'
import CalculateFiscalCode from '@/components/modal/CalculateFiscalCode.vue'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    ValidationProvider,
    ValidationObserver,
    // BSkeletonWrapper,
    // BSkeleton,
    // BSkeletonImg,
    BOverlay,
    vSelect,
    // BFormDatepicker,
    BInputGroupAppend,
    BInputGroup,
    flatPickr,
    CalculateFiscalCode,
    BFormRadio,
  },
  directives: {
    Ripple,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    generalData: {
      type: Object,
      required: false,
      default: (() => {}),
    },
  },
  data() {
    return {
      general: this.generalData,
      fiscalCodeFormValid: false,
      isChangedImage: false,
      token: `Bearer ${sessionStorage.getItem('token')}`,
      // comuni: COMUNI.map(comune => ({ comune: comune[2], prov: comune[1], key: comune[0] })),
      comuni: COMUNI.map(comune => ({ comune: comune[2], prov: comune[1], key: comune[0] })),
      province: Object.keys(PROVINCE).map(key => ({ comune: PROVINCE[key], prov: key, key })),
      profileFile: null,
      profileFileToBase64: '',
      errorPreviewBase64: false,
      required,
      selectedProvince: {},
      configFlatPicker: {
        defaultDate: 'null',
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: 'Y-m-d',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Italian, // locale for this instance only
      },
      fiscalCode: '',
    }
  },
  computed: {
    optionsLocal() {
      return this.general
      // this.$store.commit('profile/storeGeneralInformation', value)
    },
    comuniComp() {
      // console.log('comuni', this.comuni)
      // console.log('province', this.province)
      if (this.generalData.stato_nascita !== 'EE') return this.comuni.filter(comune => comune.prov === this.generalData.prov_nascita)
      return this.comuni.filter(comune => comune.prov === 'EE')
    },
    nazioniComp() {
      return this.comuni.filter(comune => comune.prov === 'EE')
    },
    /* optionsLocal() {
      console.log(this.$store.getters['profile/general'])
      return this.$store.state.profile.general
    }, */
  },
  watch: {
    generalData: {
      handler: 'checkFormFiscalCode',
      deep: true,
    },
  },
  methods: {
    checkFormFiscalCode() {
      this.calculatefiscalcode2()
      /* const { fields } = this.$refs.changeProfileSettingGeneral
      console.log(fields)
      const result = Object.values(fields).every(o => o.valid === true)
      console.log('result', result) */
    },
    addFiscalCode(obj) {
      // console.log(obj)
      const {
        name, surname, dateOfBorn, cityOfBorn, provOfBorn, nation,
      } = obj.anagrafic
      this.generalData.nome = name
      this.generalData.cognome = surname
      this.generalData.data_nascita = dateOfBorn.replace('/', '-')
      this.generalData.codice_fiscale = obj.fiscalCode
      this.generalData.citta_nascita = provOfBorn === 'EE' ? nation : cityOfBorn
      this.generalData.prov_nascita = provOfBorn === 'EE' ? 'EE' : provOfBorn
      this.generalData.stato_nascita = provOfBorn === 'EE' ? 'EE' : 'IT'
      // this.generalData.data_nascita = obj.anagrafic.dateOfBorn
      // console.log('dopo add', this.optionsLocal)
      this.$store.commit('profile/storeGeneralInformation', this.generalData)
      // this.optionsLocal.codice_fiscale = obj.fiscalCode
      // console.log(this.optionsLocal)
      // console.log(this.generalData)
      // console.log('obj', obj)
    },
    resetForm() {
      this.$emit('reset-profile')
      this.isChangedImage = false
      this.errorPreviewBase64 = false
      this.profileFile = null
      // this.generalData = { ...this.$store.state.profile.generalReset }
      this.$refs.changeProfileSettingGeneral.reset()
    },
    /*     blobToBase641(blob) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    }, */
    async previewNewImage(blob) {
      // blob.size per impostare in bytes la dimensione massima del file
      await blobToBase64(blob).then(res => {
        // this.optionsLocal.avatar = res
        if (res.substring(0, 21).includes('image')) {
          this.generalData.avatar = res
          // this.optionsLocal.avatar = this.generalData.avatar
        } else {
          this.errorPreviewBase64 = true
        }
      }).catch(() => {
        this.errorPreviewBase64 = true
      })
    },
    validationForm(bvModalEvt) {
      // console.log(this.$refs.changeProfileSettingGeneral)
      bvModalEvt.preventDefault()
      this.$refs.changeProfileSettingGeneral.validate().then(async success => {
        if (success) {
          // const cf = new CodiceFiscale(this.optionsLocal.codice_fiscale)
          // console.log(cf)
          const data = JSON.stringify([{
            nome: this.generalData.nome,
            ruolo: this.generalData.ruolo.join('#'),
            id_user: '',
            cognome: this.generalData.cognome,
            codice_fiscale: this.generalData.codice_fiscale,
            partita_iva: this.generalData.partita_iva,
            telefono: this.generalData.cellulare,
            avatar: this.generalData.avatar,
            data_nascita: this.generalData.data_nascita,
            citta_nascita: this.generalData.citta_nascita,
            prov_nascita: this.generalData.prov_nascita,
            stato_nascita: this.generalData.stato_nascita,
            codice_sdi: this.generalData.codice_sdi,
            sesso: this.generalData.sesso,
            stato: 'ATTIVO',
            // id_persona: 263,
          }])
          // console.log('data', data)
          this.$emit('update-profile', data)
          // console.log('dopo emit', this.isChangedImage)
        }
      })
    },
    somethingChangedBeforeChangeTab() {
      // console.log('this.optionsLocalFromProps', this.optionsLocalFromProps)
      // console.log('this.optionsLocal', this.optionsLocal)
      // console.log('this.$store.state.profile.general', this.$store.state.profile.general)
      // console.log('this.$store.state.profile.generalReset', this.$store.state.profile.generalReset)
      // console.log('equal', !_.isEqual(this.$store.state.profile.general, this.$store.state.profile.generalReset))
      if (!isEqual(this.$store.state.profile.general, this.$store.state.profile.generalReset)) {
        // workaround
        // this.isChangedImage = false
        return true
      }
      return false
    },
    calculatefiscalcode2() {
      const splitDateToArray = this.generalData.data_nascita !== null ? this.generalData.data_nascita.split('-') : ''
      const year = splitDateToArray[0]
      const month = splitDateToArray[1]
      const day = splitDateToArray[2]
      // console.log(this.form.anagrafic.dateOfBorn)
      // console.log(this.form.anagrafic.dateOfBorn.split('-'))
      // console.log(this.optionsLocal)
      try {
        const cf = new CodiceFiscale({
          name: this.generalData.nome,
          surname: this.generalData.cognome,
          gender: this.generalData.sesso.toUpperCase(),
          day,
          month,
          year,
          birthplace: this.generalData.citta_nascita,
          birthplaceProvincia: this.generalData.stato_nascita === 'EE' ? 'EE' : this.generalData.prov_nascita.toUpperCase(),
        })
        this.fiscalCode = cf.code
        // this.optionsLocal.codice_fiscale = this.fiscalCode
      } catch (e) { return e }
      this.generalData.codice_fiscale = this.fiscalCode
      return this.fiscalCode
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    // const base64data = blobToBase64(this.profileFile)
    // console.log(base64data.substr(base64data.indexOf(',') + 1))
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
    // const { blobToBase } = blobToBase64(this.profileFile)

    return {
      refInputEl,
      previewEl,
      // blobToBase64,
      // blobToBase,
      inputImageRenderer,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
