<template>
  <b-card>
    <b-button
      v-b-toggle="'addaddress'"
      variant="primary"
      @click="setModal('Aggiungi Recapito')"
    >Aggiungi Recapito</b-button>
    <AddAddress
      ref="addAddressProfile"
      :title="titleAddress"
      :address1="addressObj"
      @add-address="pushNewAddress"
    />
    <!-- toggle button -->
    <!-- <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-toggle.collapse-1
      variant="outline-primary"
    >
      Toggle Collapse
    </b-button> -->
    <b-collapse
      v-for="(address) in addressProfile"
      :id="address+address.id"
      :key="address+address.id"
      class="mt-2"
      visible
    >
      <b-card class="border mb-0">
        <b-row
          class="d-flex justify-content-between"
        >
          <b-col
            cols="3"
            lg="2"
            class="order-0"
          >
            <b-avatar
              width="40"
              height="40"
              variant="light-primary"
              class="mr-2"
            >
              <b-img
                v-b-toggle="'collapse-1-inner'+address.id"
                :class="{'angle-rigth-expanded': address.isCollapsed}"
                class="angle-rigth"
                width="20"
                height="20"
                :src="require('@/@core/assets/iconpro/angle-right.svg')"
                @click="address.isCollapsed = !address.isCollapsed; collapseOthers(address.id);"
              />

            </b-avatar>
          </b-col>
          <!-- <b-col cols="6">
            <p class="m-0 p-0 my-auto">
              <span class="mr-1">Tipo di Recapito: <strong>{{ address.tipo_recapito }}</strong></span><span class="mr-1">Indirizzo:<strong>{{ address.indirizzo }}</strong></span> <span class="d-block mr-2">Prov:<strong>{{ address.provincia }}</strong><span class="mx-1">Comune:<strong>{{ address.comune }}</strong></span></span><span class="d-block">CAP: <strong>{{ address.cap }}</strong><span class="mx-1">Nazione:<strong> {{ address.nazione }}</strong></span></span><span class="d-block">Presso:<strong> {{ address.presso }}</strong></span>
            </p>
          </b-col> -->
          <b-col
            cols="12"
            lg="8"
            class="order-2 order-lg-1"
          >
            <b-list-group flush>
              <b-list-group-item class="border-0">
                <b-row class="d-flex justify-content-between">
                  <b-col
                    cols="12"
                    md="6"
                    xl="4"
                  >
                    <div><span>Tipo di Recapito: </span><strong>{{ address.tipo_recapito }}</strong></div>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    xl="4"
                  >
                    <div><span>Indirizzo:</span><strong>{{ address.indirizzo }}</strong></div>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    xl="4"
                  >
                    <div><span>Comune:</span><strong>{{ address.comune }}</strong></div>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    xl="4"
                  >
                    <div><span>Prov: </span><strong>{{ address.provincia }}</strong></div>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    xl="4"
                  >
                    <div><span>CAP: </span><strong>{{ address.cap }}</strong></div>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    xl="4"
                  >
                    <div><span>Nazione:</span><strong>{{ address.nazione }}</strong></div>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    xl="4"
                  >
                    <div><span>Presso: </span><strong>{{ address.presso }}</strong></div>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <!-- <b-list-group-item class="border-0 py-0 py-md-1">
                <b-row class="d-flex justify-content-between">
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <div><span>CAP: </span><strong>{{ address.cap }}</strong></div>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <div><span>Nazione:</span><strong>{{ address.nazione }}</strong></div>
                  </b-col>
                </b-row>
              </b-list-group-item> -->
            </b-list-group>
          </b-col>
          <b-col
            cols="3"
            lg="2"
            class="d-flex justify-content-end order-lg-2"
          >
            <b-button
              v-b-toggle="'addaddress'"
              variant="flat-primary"
              class="mr-1"
              style="width:60px; height: 60px"
              @click="setModal('Modifica Recapito', address)"
            >
              <feather-icon
                icon="EditIcon"
                class=""
              /></b-button>
            <b-button
              variant="flat-warning"
              style="width:60px; height: 60px"
              @click="deleteAddress(address.id)"
            >
              <feather-icon
                icon="TrashIcon"
                class=""
              /></b-button>
          </b-col>
        </b-row>
        <b-collapse
          :id="'collapse-1-inner'+address.id"
          class="mt-2"
        >
          <h4 class="font-weight-bold">
            Contatti Associati
            <b-button
              v-b-modal="'setContactModal'"
              variant="flat-primary"
              @click="passIdRecapitoToModal(address.id, 'Aggiungi Contatto')"
            >
              <feather-icon
                icon="PlusIcon"
                class=""
              />
            </b-button>
          </h4>
          <b-row v-if="address.contatti.length === 0">
            <b-col cols="6">
              <b-alert
                variant="info"
                show
                class="p-2"
              >Non hai ancora nessun contatto associato</b-alert>
            </b-col>
          </b-row>
          <div v-if="address.isCollapsed">
            <b-row
              v-for="(contact) in address.contatti"
              :key="contact.id"
              no-gutters
              class="m-0 p-0"
            >
              <b-col
                cols="6"
                sm="6"
                md="3"
              >
                <p><strong>{{ contact.tipo_contatto }}: </strong>{{ contact.valore }}</p>
              </b-col>
              <!-- PASSARE OGGETTO CONTATTO DA MODIFICARE SU PASSIDRECAPITOTOMODAL -->
              <b-col
                cols="1"
                class="my-auto p-0 mx-1"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none p-0 align-self-end"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="deleteContact(contact.id, address.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Elimina</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-b-modal="'setContactModal'"
                    @click="passIdRecapitoToModal(address.id, 'Modifica Contatto', contact)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                    />
                    <span>Modifica</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </div>
        </b-collapse>
      </b-card>
    </b-collapse>
    <SetContact
      :id-recapito="contactToSet.id_recapito"
      :title="contactToSet.title"
      :contact-to-set="contactToSet"
    />
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BCollapse,
  VBToggle,
  BCard,
  BAvatar,
  BImg,
  BButton,
  BDropdownItem,
  BDropdown,
  BAlert,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { COMUNI } from '@core/fiscalcode/lists/lista-comuni'
import { PROVINCE } from '@core/fiscalcode/lists/lista-province'
import axios from 'axios'
import AddAddress from './components/AddAddress.vue'
import SetContact from './components/SetContact.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCollapse,
    BAvatar,
    BButton,
    AddAddress,
    SetContact,
    BImg,
    BDropdownItem,
    BDropdown,
    BAlert,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      selectedProvince: '',
      selectedComune: '',
      comuni: COMUNI.map(comune => ({ comune: comune[2], prov: comune[1], key: comune[0] })),
      province: Object.keys(PROVINCE).map(key => key),
      optionAddress: [
        { value: 'RESIDENZA', text: 'Residenza' },
        { value: 'DOMICILIO', text: 'Domicilio' },
        { value: 'LAVORO', text: 'Lavoro' },
        { value: 'SEDE LAVORATIVA', text: 'Sede Lavorativa' },
        { value: 'SEDE OPERATIVA', text: 'Sede Operativa' },
        { value: 'SEDE LEGALE', text: 'Sede Legale' },
      ],
      contactToSet: {
        id_recapito: 0,
        title: 'Aggiungi Contatto',
        id: '',
        tipo_contatto: '',
        valore: '',
      },
      titleAddress: '',
      addressObj: {
        tipo_recapito: '',
        indirizzo: '',
        provincia: '',
        comune: '',
        cap: '',
        nazione: 'Italia',
        presso: '',
      },
    }
  },
  computed: {
    addressProfile() {
      // console.log(this.$store.getters['profile/getAddress'])
      return this.$store.getters['profile/getAddress']
    },
    comuniComp() {
      return this.comuni.filter(comune => comune.prov === this.selectedProvince.prov)
    },
    nationComp() {
      return this.comuni.filter(comune => comune.prov === 'EE')
    },
    contactComp() {
      return []
    },
  },
  methods: {
    somethingChangedBeforeChangeTab() {
      return false
    },
    resetForm() {
      return true
    },
    setModal(title, address) {
      // console.log(this.$refs, title)
      this.titleAddress = title
      // console.log('modal', address)
      if (address) {
        const {
          tipo_recapito,
          indirizzo,
          provincia,
          comune,
          cap,
          nazione,
          presso,
          id,
        } = address
        this.addressObj = {
          id_recapito: id,
          tipo_recapito,
          indirizzo,
          provincia,
          comune,
          cap,
          nazione,
          presso,
        }
      } else {
        this.addressObj = {
          tipo_recapito: '',
          indirizzo: '',
          provincia: '',
          comune: '',
          cap: '',
          nazione: 'Italia',
          presso: '',
        }
      }
    },
    passIdRecapitoToModal(idRecapito, title, contact) {
      /* console.log('idRecapito', idRecapito)
      console.log('title', title)
      console.log('contact', contact) */
      this.contactToSet.id_recapito = idRecapito // idRecapito
      if (title === 'Modifica Contatto') {
        this.contactToSet.title = title
        this.contactToSet.id_recapito = contact.id_recapito
        this.contactToSet.id = contact.id
        this.contactToSet.tipo_contatto = contact.tipo_contatto
        this.contactToSet.valore = contact.valore
        // passa l'oggetto da modificare
      } else {
        this.contactToSet.title = 'Aggiungi Contatto'
        this.contactToSet.tipo_contatto = ''
        this.contactToSet.valore = ''
        this.contactToSet.id_recapito = idRecapito
      }
    },
    provinceSelected(provincia) {
      if (provincia !== null) {
        return this.comuni.filter(comune => comune.prov === provincia)
      }
      return []
    },
    changeComune(id, address) {
      // console.log('changeComune', id, address)
      if (address !== null) {
        const index = this.$store.state.profile.address.findIndex(el => el.id === id)
        this.$store.state.profile.address[index].comune = address.comune
      }
      return []
      // console.log('change comune', this.$store.state.profile.address)
    },
    changeNation(id, address) {
      // console.log('changeComune', id, address)
      const index = this.$store.state.profile.address.findIndex(el => el.id === id)
      this.$store.state.profile.address[index].nazione = address.comune
      // console.log('change comune', this.$store.state.profile.address)
    },
    typeAddress(id, address) {
      // console.log('type address', address)
      // this.addressProfile[index].tipo_recapito = address.value
      const index = this.$store.state.profile.address.findIndex(el => el.id === id)
      this.$store.state.profile.address[index].tipo_recapito = address.value
      // console.log('change type address', this.$store.state.profile.address)
    },
    editContact(contact) {
      // console.log('edit', contact)
      this.$emit('update-contact', contact)
    },
    pushNewAddress(value) {
      this.$store.state.profile.address.unshift(value)
      // console.log('valueeee', value)
    },
    collapseOthers(indexToExclude) {
      this.$store.state.profile.address.forEach((element, index) => {
        if (element.id !== indexToExclude && element.isCollapsed) {
          const id = `collapse-1-inner${element.id}`
          this.$store.state.profile.address[index].isCollapsed = false
          this.$store.state.profile.address[index].disabled = true
          this.$root.$emit('bv::toggle::collapse', id)
          // this.$store.state.profile.address[index].isCollapsed = false
        }
      })
    },
    async deleteContact(contactId, addressId) {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, { id_contatto: contactId },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Persona',
            metodo: 'deleteContatto',
            ACL: 'invoice-list',
          },
        }).then(res => {
        // console.log('delete contatto', res)
        if (res.data.esito === 'OK') {
          const id = res.data.contatti
          const indexAddress = this.$store.state.profile.address.findIndex(el => el.id === addressId)
          const indexContact = this.$store.state.profile.address[indexAddress].contatti.findIndex(el => el.id === id)
          // console.log('detele indexAddress', indexAddress)
          // console.log('detele indexContact', indexContact)
          this.$store.state.profile.address[indexAddress].contatti.splice(indexContact, 1)
        }
      }).catch(e => e)
    },
    async deleteAddress(id) {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, { id_recapito: id },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Persona',
            metodo: 'deleteRecapito',
            ACL: 'invoice-list',
          },
        }).then(res => {
        // console.log('delete recapito', res)
        // console.log('delete this.$store.state.profilo.address', this.$store.state.profile.address)
        if (res.data.esito === 'OK') {
          const index = res.data.recapiti
          // console.log('index', index)
          // console.log('index2', this.$store.state.profile.address)
          const findIndex = this.$store.state.profile.address.findIndex(el => el.id === index)
          // const findIndex = this.$store.state.profile.address[0]
          // console.log('findindex', findIndex)
          this.$store.state.profile.address.splice(findIndex, 1)
        }
      }).catch(e => e)
    },
  },
}
</script>
<style scoped>
.angle-rigth {
    transition: .3s all;  /* rotate gradually instead of instantly */
}

.angle-rigth-expanded {
    -webkit-transform: rotate(90deg);  /* to support Safari and Android browser */
    -ms-transform: rotate(90deg);      /* to support IE 9 */
    transform: rotate(90deg);
}
.list-group .list-group-item:hover{
  background-color: transparent !important;
}
</style>
