<template>
  <b-modal
    id="calculateFiscalCode"
    ref="refCalculateFiscalCode"
    centered
    title="Calcola Codice Fiscale"
    hide-footer
    no-close-on-backdrop
  >
    <b-alert
      :show="showFiscalCode"
      :variant="fiscalCode.length === 16 ? 'success' : 'danger'"
      class="p-1 mb-2 text-center m-auto"
    ><span class="m-1">{{ fiscalCode.length === 16 ? fiscalCode : 'Errore nel Calcolo, controlla i dati inseriti' }}</span></b-alert>
    <validation-observer
      ref="ref-collaborator-calculate-code-fiscal"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Nazionalità"
              rules="required"
            >
              <b-form-group
                label="Nazionalità"
                label-for="collaborator-nation"
                label-class="font-weight-bolder"
                class="mt-2"
              >
                <div
                  id="collaborator-nation"
                  class="demo-inline"
                >
                  <b-form-radio
                    v-model="anagrafic.nation"
                    name="italian"
                    value="IT"
                  > Italiana
                  </b-form-radio>
                  <b-form-radio
                    v-model="anagrafic.nation"
                    name="not-italian"
                    value="EE"
                  > Estera
                  </b-form-radio>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              label="Inserisci Nome"
              label-for="collaborator-first-name"
              label-class="font-weight-bolder"
              class="mr-sm-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Nome"
                rules="required"
              >
                <b-form-input
                  id="collaborator-first-name"
                  v-model="anagrafic.name"
                  placeholder="Nome"
                  autocomplete="nope"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              label="Inserisci Cognome"
              label-for="collaborator-surname"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="Cognome"
                rules="required"
              >
                <b-form-input
                  id="collaborator-surname"
                  v-model="anagrafic.surname"
                  placeholder="Cognome"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="9"
            sm="6"
            class="mr-md-1"
          >
            <!-- <validation-provider
              #default="{ errors }"
              name="Data di Nascita"
              rules="required"
            > -->
            <validation-provider
              #default="{ errors }"
              name="Data di Nascita"
              rules="required"
            >
              <b-form-group
                :label="$t('personalProfile.information.placeholder.dateBirth')"
                label-for="collaborator-dateofBith"
                label-class="font-weight-bolder"
              >
                <flatPickr
                  id="collaborator-dateofBith"
                  v-model="anagrafic.dateOfBorn"
                  class="form-control"
                  :config="configFlatPicker"
                  :placeholder="$t('personalProfile.information.placeholder.dateBirth')"
                /> <!-- {{ anagrafic.dateOfBorn }} -->
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <!-- </validation-provider> -->
          </b-col>
          <b-col
            cols="3"
            sm="5"
          >
            <b-form-group
              label="Sesso"
              label-for="collaborator-gender"
              label-class="font-weight-bolder"
            >
              <div
                id="collaborator-gender"
                class="demo-inline"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Sesso"
                  rules="required"
                >
                  <b-form-radio
                    v-model="anagrafic.gender"
                    name="M"
                    value="M"
                  > M
                  </b-form-radio>
                  <b-form-radio
                    v-model="anagrafic.gender"
                    name="F"
                    value="F"
                  > F
                  </b-form-radio>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="anagrafic.nation !== 'EE'"
            cols="12"
          >
            <b-form-group
              :label="'Prov di Nascita'"
              label-for="anagrafic-city-born"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                :name="'Prov di Nascita'"
                rules="required"
              >
                <v-select
                  v-model="selectedProvince"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="comune"
                  :clearable="false"
                  filterable
                  :options="province"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              :label="anagrafic.nation === 'EE' ? 'Nazione di Nascita' : 'Comune di Nascita'"
              label-for="anagrafic-city-born"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                :name="anagrafic.nation === 'EE' ? 'Nazione di Nascita' : 'Comune di Nascita'"
                rules="required"
              >
                <v-select
                  v-model="selectedComune"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="comune"
                  :clearable="false"
                  filterable
                  :options="comuniComp"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button
          variant="primary"
          class="mb-1"
          @click="calculateFiscalCode"
        >Calcola</b-button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal, BFormGroup, BFormInput, BCol, BRow, BForm, BFormRadio, BButton, BAlert,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import CodiceFiscale from '@core/fiscalcode/dist/codice.fiscale.amd'
import { COMUNI } from '@core/fiscalcode/lists/lista-comuni'
import { PROVINCE } from '@core/fiscalcode/lists/lista-province'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Italian } from 'flatpickr/dist/l10n/it'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BCol,
    BRow,
    BForm,
    // BInputGroup,
    // BFormDatepicker,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      selectedComune: '',
      selectedProvince: '',
      comuni: COMUNI.map(comune => ({ comune: comune[2], prov: comune[1], key: comune[0] })),
      province: Object.keys(PROVINCE).map(key => ({ comune: PROVINCE[key], prov: key, key })),
      anagrafic: {
        name: '',
        surname: '',
        cityOfBorn: '',
        provOfBorn: '',
        dateOfBorn: null,
        gender: '',
        nation: '',
      },
      fiscalCode: '',
      showFiscalCode: false,
      configFlatPicker: {
        static: true,
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: 'M/j/Y',
        altInput: true,
        dateFormat: 'Y/m/d',
        locale: Italian, // locale for this instance only
      },
    }
  },
  computed: {
    comuniComp() {
      if (this.anagrafic.nation !== 'EE') return this.comuni.filter(comune => comune.prov === this.selectedProvince.prov)
      return this.comuni.filter(comune => comune.prov === 'EE')
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    /* 'anagrafic.nation': function () {
      this.selectedComune = ''
      this.selectedProvince = ''
    }, */
  },
  methods: {
    calculateFiscalCode() {
      this.$refs['ref-collaborator-calculate-code-fiscal'].validate().then(success => {
        if (success) {
          this.fiscalCode = ''
          const splitDateToArray = this.anagrafic.dateOfBorn.split('/')
          const year = splitDateToArray[0]
          const month = splitDateToArray[1]
          const day = splitDateToArray[2]
          // console.log(this.form.anagrafic.dateOfBorn)
          // console.log(this.form.anagrafic.dateOfBorn.split('-'))
          try {
            const cf = new CodiceFiscale({
              name: this.anagrafic.name,
              surname: this.anagrafic.surname,
              gender: this.anagrafic.gender.toUpperCase(),
              day,
              month,
              year,
              birthplace: this.selectedComune.comune,
              birthplaceProvincia: this.anagrafic.nation === 'EE' ? 'EE' : this.selectedProvince.prov,
            })
            this.fiscalCode = cf.code
            // console.log(cf)
            /* this.anagrafic.dateOfBorn = `${cf.year}-${cf.month}-${cf.day}`
            this.anagrafic.cityOfBorn = cf.birthplace.prov !== 'EE' ? cf.birthplace.nome : ''
            this.anagrafic.provOfBorn = cf.birthplace.prov
            this.anagrafic.nation = cf.birthplace.prov !== 'EE' ? 'Italia' : cf.birthplace.nome */
            this.showFiscalCode = true
            this.$emit('fiscalcode',
              {
                fiscalCode: this.fiscalCode,
                anagrafic: {
                  name: this.anagrafic.name,
                  surname: this.anagrafic.surname,
                  dateOfBorn: this.anagrafic.dateOfBorn,
                  cityOfBorn: cf.birthplace.prov !== 'EE' ? cf.birthplace.nome : '',
                  provOfBorn: cf.birthplace.prov,
                  nation: cf.birthplace.prov !== 'EE' ? 'Italia' : cf.birthplace.nome,
                  gender: this.anagrafic.gender.toUpperCase(),
                },
              })
            /* console.log(
              'wow',
              {
                anagrafic: {
                  dateOfBorn: `${cf.year}-${cf.month}-${cf.day}`,
                  cityOfBorn: cf.birthplace.prov !== 'EE' ? cf.birthplace.nome : '',
                  provOfBorn: cf.birthplace.prov,
                  nation: cf.birthplace.prov !== 'EE' ? 'Italia' : cf.birthplace.nome,
                },
              },
            ) */
            this.hideModal()
          } catch (err) {
            // console.log('errore', err)
            this.showFiscalCode = true
          }
        }
      })
      // console.log(cf)
    },
    hideModal() {
      this.$refs.refCalculateFiscalCode.hide()
    },
    resetModal() {
      // this.typeAddressSelected = ''
    },
    print(e) {
      e.preventDefault()
      // console.log(this.fiscalCode)
      // console.log(this.anagrafic.provOfBorn.toUpperCase())
      // this.$refs.addaddress.hide()
      // this.$root.$on('bv::modal::hide', 'addaddress')
      // this.$refs.addaddress.hide()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
