var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"refCalculateFiscalCode",attrs:{"id":"calculateFiscalCode","centered":"","title":"Calcola Codice Fiscale","hide-footer":"","no-close-on-backdrop":""}},[_c('b-alert',{staticClass:"p-1 mb-2 text-center m-auto",attrs:{"show":_vm.showFiscalCode,"variant":_vm.fiscalCode.length === 16 ? 'success' : 'danger'}},[_c('span',{staticClass:"m-1"},[_vm._v(_vm._s(_vm.fiscalCode.length === 16 ? _vm.fiscalCode : 'Errore nel Calcolo, controlla i dati inseriti'))])]),_c('validation-observer',{ref:"ref-collaborator-calculate-code-fiscal"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Nazionalità","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Nazionalità","label-for":"collaborator-nation","label-class":"font-weight-bolder"}},[_c('div',{staticClass:"demo-inline",attrs:{"id":"collaborator-nation"}},[_c('b-form-radio',{attrs:{"name":"italian","value":"IT"},model:{value:(_vm.anagrafic.nation),callback:function ($$v) {_vm.$set(_vm.anagrafic, "nation", $$v)},expression:"anagrafic.nation"}},[_vm._v(" Italiana ")]),_c('b-form-radio',{attrs:{"name":"not-italian","value":"EE"},model:{value:(_vm.anagrafic.nation),callback:function ($$v) {_vm.$set(_vm.anagrafic, "nation", $$v)},expression:"anagrafic.nation"}},[_vm._v(" Estera ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{staticClass:"mr-sm-1",attrs:{"label":"Inserisci Nome","label-for":"collaborator-first-name","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"collaborator-first-name","placeholder":"Nome","autocomplete":"nope"},model:{value:(_vm.anagrafic.name),callback:function ($$v) {_vm.$set(_vm.anagrafic, "name", $$v)},expression:"anagrafic.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Inserisci Cognome","label-for":"collaborator-surname","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Cognome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"collaborator-surname","placeholder":"Cognome"},model:{value:(_vm.anagrafic.surname),callback:function ($$v) {_vm.$set(_vm.anagrafic, "surname", $$v)},expression:"anagrafic.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mr-md-1",attrs:{"cols":"9","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Data di Nascita","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('personalProfile.information.placeholder.dateBirth'),"label-for":"collaborator-dateofBith","label-class":"font-weight-bolder"}},[_c('flatPickr',{staticClass:"form-control",attrs:{"id":"collaborator-dateofBith","config":_vm.configFlatPicker,"placeholder":_vm.$t('personalProfile.information.placeholder.dateBirth')},model:{value:(_vm.anagrafic.dateOfBorn),callback:function ($$v) {_vm.$set(_vm.anagrafic, "dateOfBorn", $$v)},expression:"anagrafic.dateOfBorn"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"3","sm":"5"}},[_c('b-form-group',{attrs:{"label":"Sesso","label-for":"collaborator-gender","label-class":"font-weight-bolder"}},[_c('div',{staticClass:"demo-inline",attrs:{"id":"collaborator-gender"}},[_c('validation-provider',{attrs:{"name":"Sesso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"name":"M","value":"M"},model:{value:(_vm.anagrafic.gender),callback:function ($$v) {_vm.$set(_vm.anagrafic, "gender", $$v)},expression:"anagrafic.gender"}},[_vm._v(" M ")]),_c('b-form-radio',{attrs:{"name":"F","value":"F"},model:{value:(_vm.anagrafic.gender),callback:function ($$v) {_vm.$set(_vm.anagrafic, "gender", $$v)},expression:"anagrafic.gender"}},[_vm._v(" F ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1)],1),_c('b-row',[(_vm.anagrafic.nation !== 'EE')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Prov di Nascita',"label-for":"anagrafic-city-born","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":'Prov di Nascita',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"comune","clearable":false,"filterable":"","options":_vm.province},model:{value:(_vm.selectedProvince),callback:function ($$v) {_vm.selectedProvince=$$v},expression:"selectedProvince"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2822300423)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.anagrafic.nation === 'EE' ? 'Nazione di Nascita' : 'Comune di Nascita',"label-for":"anagrafic-city-born","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":_vm.anagrafic.nation === 'EE' ? 'Nazione di Nascita' : 'Comune di Nascita',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"comune","clearable":false,"filterable":"","options":_vm.comuniComp},model:{value:(_vm.selectedComune),callback:function ($$v) {_vm.selectedComune=$$v},expression:"selectedComune"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-button',{staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":_vm.calculateFiscalCode}},[_vm._v("Calcola")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }