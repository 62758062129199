<template>
  <b-sidebar
    id="addaddress"
    ref="addaddress"
    :title="title"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    backdrop-variant="dark"
    backdrop
    :no-close-on-backdrop="true"
    shadow
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ title }}
        </h5>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide()"
          />
        </div>
      </div>
      <validation-observer
        ref="add-address-validator"
      >
        <b-form>
          <b-row class="m-1">
            <b-col
              cols="12"
              lg="8"
            >
              <validation-provider
                #default="{ errors }"
                name="Tipo Recapito"
                rules="required"
              >
                <b-form-group
                  :label="'Tipo di Recapito'"
                  label-class="font-weight-bolder"
                >
                  <v-select
                    v-model="address1.tipo_recapito"
                    class=""
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    taggable
                    :clearable="false"
                    filterable
                    :options="optionAddress"
                    placeholder="Tipo Recapito"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              lg="8"
            >
              <validation-provider
                #default="{ errors }"
                name="Indirizzo"
                rules="required"
              >
                <b-form-group
                  :label="'Indirizzo'"
                  label-class="font-weight-bolder"
                >
                  <b-form-input
                    v-model="address1.indirizzo"
                    placeholder="Indirizzo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              lg="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Nazione"
                rules="required"
              >
                <b-form-group
                  :label="'Nazione'"
                  label-class="font-weight-bolder"
                >
                  <b-form-radio-group
                    v-model="nazionalita"
                    :options="options"
                    name="radios-stacked"
                    stacked
                    @change="address1.comune = '';nazionalita === 'Italia' ? address1.nazione = 'Italia' : address1.nazione = '';"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              lg="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Provincia"
                rules="required|length:2"
              >
                <b-form-group
                  :label="'Provincia'"
                  label-class="font-weight-bolder"
                >
                  <v-select
                    v-model="address1.provincia"
                    class="mr-lg-1"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="prov"
                    taggable
                    :clearable="false"
                    filterable
                    :create-option="prov => prov"
                    :options="province"
                    placeholder="Provincia"
                  >
                    <!-- eslint-disable-next-line vue/no-unused-vars  -->
                    <template #no-options="{ search, searching, loading }">
                      <div v-if="nazionalita === 'Italia'">
                        Digita la tua Prov
                      </div>
                      <div v-else>
                        Inserisci Prov
                      </div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              lg="8"
            >
              <validation-provider
                #default="{ errors }"
                :name="nazionalita === 'Italia' ? 'Comune' : 'Città'"
                rules="required"
              >
                <b-form-group
                  :label="nazionalita === 'Italia' ? 'Comune' : 'Città'"
                  label-class="font-weight-bolder"
                >
                  <v-select
                    v-model="address1.comune"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="comune"
                    :clearable="false"
                    value="comune"
                    taggable
                    :reduce="prov => prov.comune"
                    filterable
                    :create-option="comune => ({ comune: comune, key: '', prov: 'EE' })"
                    :options="provinceSelected"
                    :placeholder="nazionalita === 'Italia' ? 'Comune' : 'Città'"
                  >
                    <!-- eslint-disable-next-line vue/no-unused-vars  -->
                    <template #no-options="{ search, searching, loading }">
                      <div v-if="nazionalita === 'Italia'">
                        Digita la tua Città
                      </div>
                      <div v-else>
                        Nessun Comune Trovato. Digita il tuo..
                      </div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              lg="4"
              class=""
            >
              <validation-provider
                #default="{ errors }"
                name="CAP"
                rules="required"
              >
                <b-form-group
                  :label="'CAP'"
                  label-class="font-weight-bolder"
                >
                  <b-form-input
                    v-model="address1.cap"
                    class="mr-lg-1"
                    placeholder="CAP"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              lg="8"
            >
              <validation-provider
                #default="{ errors }"
                name="Stato"
                rules="required"
              >
                <b-form-group
                  :label="'Stato'"
                  label-class="font-weight-bolder"
                >
                  <v-select
                    v-model="address1.nazione"
                    class=""
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    filterable
                    :clearable="false"
                    label="comune"
                    value="comune"
                    taggable
                    :reduce="prov => prov.comune"
                    :disabled="nazionalita === 'Italia'"
                    :options="nationComp"
                    placeholder="Stato"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Presso"
                rules="required"
              >
                <b-form-group
                  :label="'Presso'"
                  label-class="font-weight-bolder"
                >
                  <b-form-input
                    v-model="address1.presso"
                    placeholder="Presso"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-row class="m-1">
        <b-col class="d-flex justify-content-end">
          <b-button
            variant="primary"
            @click="addAddress"
          >Salva</b-button>
        </b-col>
      </b-row>
      <!-- {{ address }}
      <br>
      aadreesss1 {{ address1 }} -->
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar, BFormGroup, BFormInput, BCol, BRow, BButton, BFormRadioGroup, BForm,
} from 'bootstrap-vue'
import { COMUNI } from '@core/fiscalcode/lists/lista-comuni'
import { PROVINCE } from '@core/fiscalcode/lists/lista-province'
import vSelect from 'vue-select'
import axios from 'axios'

export default {
  components: {
    BSidebar,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    vSelect,
    BButton,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    address1: {
      type: Object,
      default: (() => {}),
    },
  },
  data() {
    return {
      options: [
        { text: 'Italia', value: 'Italia' },
        { text: 'Estero', value: 'EE' },
      ],
      optionAddress: [
        'RESIDENZA', 'DOMICILIO', 'LAVORO', 'SEDE LAVORATIVA', 'SEDE OPERATIVA', 'SEDE LEGALE',
      ],
      selectedProvincia: '',
      nationType: '',
      comuni: COMUNI.map(comune => ({ comune: comune[2], prov: comune[1], key: comune[0] })),
      dataProvince: Object.keys(PROVINCE).map(key => key),
      nazionalita: this.address1.nazione,
      address: {
        typeAddress: {},
        indirizzo: '',
        comune: {},
        prov: '',
        nazione: 'Italia',
        cap: '',
        presso: '',
      },
    }
  },
  computed: {
    province() {
      if (this.nazionalita !== 'Italia') {
        return [
        ]
      }
      return this.dataProvince
    },
    comuniComp() {
      return this.comuni.filter(comune => comune.prov === this.address1.provincia)
    },
    nationComp() {
      return this.comuni.filter(comune => comune.prov === 'EE')
    },
    provinceSelected() {
      if (this.nazionalita !== 'Italia') {
        return [
        ]
      }
      return this.comuni.filter(comune => comune.prov === this.address1.provincia)
    },
  },
  methods: {
    addAddress() {
      this.$refs['add-address-validator'].validate().then(success => {
        if (success) {
          /* const data = {
            tipo_recapito: this.address.typeAddress.value,
            indirizzo: this.address.indirizzo,
            provincia: this.address.prov,
            comune: this.address.comune.comune,
            cap: this.address.cap,
            nazione: this.address.nazione,
            presso: this.address.presso,
          } */
          // console.log('addressss', this.address1)
          axios.post(process.env.VUE_APP_URL_API_SERVER, this.address1,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Persona',
                metodo: 'setRecapito',
                ACL: 'invoice-list',
              },
            }).then(res => {
            // console.log(res)
            if (res.data.esito === 'OK') {
              const addressResponse = res.data.recapiti
              // console.log('addressResponse', addressResponse)
              const findIndexStateAddress = this.$store.state.profile.address.findIndex(el => el.id === addressResponse.id)
              // console.log('findIndexState', findIndexStateAddress)
              if (findIndexStateAddress >= 0) {
                // console.log('trovato', this.$store.state.profile.address[findIndexStateAddress])
                this.$store.state.profile.address.splice(findIndexStateAddress, 0)
                this.$store.state.profile.address[findIndexStateAddress] = { ...addressResponse, disabled: true, isCollapsed: true }
                // console.log(this.$store.state.profile.address[findIndexStateAddress])
              } else {
                // console.log('dentro else')
                this.$store.state.profile.address.unshift({ ...addressResponse, isCollapsed: false })
              }
              // const addressEdited = { ...res.data.recapiti }
              // this.$store.state.profile.address.unshift({ ...res.data.recapiti, disabled: true, isCollapsed: false })
              // const index = this.$store.state.profile.address.findIndex(el => el.id === addressEdited.id)
              // this.$store.state.profile.address[index] = { ...addressEdited }
              this.alertSuccess()
              this.$refs.addaddress.hide()
              // this.$emit('add-address', data)
            }
          }).catch(e => e)
          /* this.address = {
            typeAddress: {},
            indirizzo: '',
            comune: {},
            prov: '',
            nazione: 'Italia',
            cap: '',
            presso: '',
          } */
          // this.$refs.addaddress.hide()
        }
      })
    },
    alertSuccess() {
      this.$swal({
        title: 'Salvataggio Effettuato Correttamente',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    // this.$refs.addaddress.hide()
    // e.preventDefault()
    // this.$refs.addaddress.hide()
    // this.$root.$on('bv::modal::hide', 'addaddress')
    // this.$refs.addaddress.hide()
  },
}
</script>

<style>

</style>
