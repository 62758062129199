var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{ref:"addaddress",attrs:{"id":"addaddress","title":_vm.title,"sidebar-class":"sidebar-lg","bg-variant":"white","backdrop-variant":"dark","backdrop":"","no-close-on-backdrop":true,"shadow":"","no-header":"","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){return hide()}}})],1)]),_c('validation-observer',{ref:"add-address-validator"},[_c('b-form',[_c('b-row',{staticClass:"m-1"},[_c('b-col',{attrs:{"cols":"12","lg":"8"}},[_c('validation-provider',{attrs:{"name":"Tipo Recapito","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Tipo di Recapito',"label-class":"font-weight-bolder"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","taggable":"","clearable":false,"filterable":"","options":_vm.optionAddress,"placeholder":"Tipo Recapito"},model:{value:(_vm.address1.tipo_recapito),callback:function ($$v) {_vm.$set(_vm.address1, "tipo_recapito", $$v)},expression:"address1.tipo_recapito"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"8"}},[_c('validation-provider',{attrs:{"name":"Indirizzo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Indirizzo',"label-class":"font-weight-bolder"}},[_c('b-form-input',{attrs:{"placeholder":"Indirizzo"},model:{value:(_vm.address1.indirizzo),callback:function ($$v) {_vm.$set(_vm.address1, "indirizzo", $$v)},expression:"address1.indirizzo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Nazione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Nazione',"label-class":"font-weight-bolder"}},[_c('b-form-radio-group',{attrs:{"options":_vm.options,"name":"radios-stacked","stacked":""},on:{"change":function($event){_vm.address1.comune = '';_vm.nazionalita === 'Italia' ? _vm.address1.nazione = 'Italia' : _vm.address1.nazione = '';}},model:{value:(_vm.nazionalita),callback:function ($$v) {_vm.nazionalita=$$v},expression:"nazionalita"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Provincia","rules":"required|length:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Provincia',"label-class":"font-weight-bolder"}},[_c('v-select',{staticClass:"mr-lg-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"prov","taggable":"","clearable":false,"filterable":"","create-option":function (prov) { return prov; },"options":_vm.province,"placeholder":"Provincia"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [(_vm.nazionalita === 'Italia')?_c('div',[_vm._v(" Digita la tua Prov ")]):_c('div',[_vm._v(" Inserisci Prov ")])]}}],null,true),model:{value:(_vm.address1.provincia),callback:function ($$v) {_vm.$set(_vm.address1, "provincia", $$v)},expression:"address1.provincia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"8"}},[_c('validation-provider',{attrs:{"name":_vm.nazionalita === 'Italia' ? 'Comune' : 'Città',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.nazionalita === 'Italia' ? 'Comune' : 'Città',"label-class":"font-weight-bolder"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"comune","clearable":false,"value":"comune","taggable":"","reduce":function (prov) { return prov.comune; },"filterable":"","create-option":function (comune) { return ({ comune: comune, key: '', prov: 'EE' }); },"options":_vm.provinceSelected,"placeholder":_vm.nazionalita === 'Italia' ? 'Comune' : 'Città'},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [(_vm.nazionalita === 'Italia')?_c('div',[_vm._v(" Digita la tua Città ")]):_c('div',[_vm._v(" Nessun Comune Trovato. Digita il tuo.. ")])]}}],null,true),model:{value:(_vm.address1.comune),callback:function ($$v) {_vm.$set(_vm.address1, "comune", $$v)},expression:"address1.comune"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"CAP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'CAP',"label-class":"font-weight-bolder"}},[_c('b-form-input',{staticClass:"mr-lg-1",attrs:{"placeholder":"CAP"},model:{value:(_vm.address1.cap),callback:function ($$v) {_vm.$set(_vm.address1, "cap", $$v)},expression:"address1.cap"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"8"}},[_c('validation-provider',{attrs:{"name":"Stato","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Stato',"label-class":"font-weight-bolder"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"filterable":"","clearable":false,"label":"comune","value":"comune","taggable":"","reduce":function (prov) { return prov.comune; },"disabled":_vm.nazionalita === 'Italia',"options":_vm.nationComp,"placeholder":"Stato"},model:{value:(_vm.address1.nazione),callback:function ($$v) {_vm.$set(_vm.address1, "nazione", $$v)},expression:"address1.nazione"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Presso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Presso',"label-class":"font-weight-bolder"}},[_c('b-form-input',{attrs:{"placeholder":"Presso"},model:{value:(_vm.address1.presso),callback:function ($$v) {_vm.$set(_vm.address1, "presso", $$v)},expression:"address1.presso"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1)],1),_c('b-row',{staticClass:"m-1"},[_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addAddress}},[_vm._v("Salva")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }