<template>
  <!-- <b-tabs
    ref="tabs-profile-setting"
    pills
    @activate-tab="changeTabController"
  > -->
  <b-tabs
    ref="tabs-profile-setting"
    v-model="currentTab"
    vertical
    pills
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
  >
    <!--  @activate-tab="changeTabController" -->

    <!-- general tab -->
    <b-tab
      ref="tabProfileSetting"
    >

      <!-- title -->
      <template #title>
        <img
          :src="currentTab === 0 ? require('@/@core/assets/icone-light-dark/profile/user-dark.svg') : require('@/@core/assets/icone-light-dark/profile/user.svg')"
          width="18"
          height="18"
          class="mr-1"
        >
        <span class="font-weight-bold">{{ $t('personalProfile.tabsMenu.general') }}</span>
      </template>
      <ProfileSettingGeneral
        v-if="optionDiProva"
        ref="profile-setting-general"
        :general-data="optionDiProva"
        :loading="loading"
        @reset-profile="reset"
        @update-profile="updateGeneral"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('personalProfile.tabsMenu.changePassword') }}</span>
      </template>

      <ProfileSettingPassword
        ref="profile-setting-password"
        :loading="loading"
      />
    </b-tab>
    <!--/ change password tab -->

    <!-- <b-tab>
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Recapiti NEW</span>
      </template>

      <ProfileSettingAddress
        v-if="option.info"
        ref="profile-setting-address2"
        @update-address="updateAddress"
      />
    </b-tab> -->

    <b-tab>

      <!-- title -->
      <template #title>
        <img
          :src="currentTab === 2 ? require('@/@core/assets/icone-light-dark/profile/location-dot.svg') : require('@/@core/assets/icone-light-dark/profile/location-dot-dark.svg')"
          width="18"
          height="18"
          class="mr-1"
        >
        <span class="font-weight-bold">Recapiti</span>
        <!-- <span class="font-weight-bold">{{ $t('personalProfile.tabsMenu.information') }}</span> -->
      </template>

      <ProfileSettingAddress2
        v-if="option.info"
        ref="profile-setting-address"
      />
    </b-tab>

    <!-- social links -->
    <!-- <b-tab>
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold mr-3">{{ $t('personalProfile.tabsMenu.social') }}</span>
        <b-badge variant="dark">
          TO DO
        </b-badge>
      </template>

      <ProfileSettingSocial
        v-if="option.social"
        ref="profile-setting-social"
        :social-data="option.social"
      />
    </b-tab> -->

    <!-- notification -->
    <b-tab>

      <!-- title -->
      <template #title>
        <img
          :src="currentTab === 3 ? require('@/@core/assets/icone-light-dark/profile/globe-dark.svg') : require('@/@core/assets/icone-light-dark/profile/globe.svg')"
          width="18"
          height="18"
          class="mr-1"
        >
        <span class="font-weight-bold mr-3">{{ $t('personalProfile.tabsMenu.notifications') }}</span>
      </template>

      <ProfileSettingNotification
        v-if="preferences"
        ref="profile-setting-notification"
        :loading="loading"
        @update-preferences="updatePreferences"
        @update-contact="updateContact"
      />
    </b-tab>
    <!--         :notification-data="preferences"
        :notification-data-for="preferences" -->
    <!-- <b-tab>
      <template #title>
        <feather-icon
          icon="Link2Icon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('personalProfile.tabsMenu.integrations') }}</span>
        <b-badge variant="dark">
          TO DO
        </b-badge>
      </template>

      <ProfileSettingIntegrations />
    </b-tab> -->
  </b-tabs>
</template>

<script>
/* eslint-disable global-require */
// import { localize } from 'vee-validate'
import { BTabs, BTab } from 'bootstrap-vue'
import axios from 'axios'
import ProfileSettingGeneral from './ProfileSettingGeneral.vue'
import ProfileSettingPassword from './ProfileSettingPassword.vue'
// import ProfileSettingAddress from './ProfileSettingAddress.vue'
import ProfileSettingAddress2 from './ProfileSettingAddress2.vue'
// import ProfileSettingSocial from './ProfileSettingSocial.vue'
import ProfileSettingNotification from './ProfileSettingNotification.vue'
// import ProfileSettingIntegrations from './ProfileSettingIntegrations.vue'

export default {
  components: {
    BTabs,
    BTab,
    ProfileSettingGeneral,
    ProfileSettingPassword,
    // ProfileSettingAddress,
    // ProfileSettingSocial,
    ProfileSettingNotification,
    // ProfileSettingIntegrations,
    // BBadge,
    ProfileSettingAddress2,
  },
  data() {
    return {
      currentTab: 0,
      loading: true,
      optionDiProva: {},
      optionDiProvaReset: {},
      preferences: [],
      preferencesReset: [],
      userAddress: [],
      options: {
        general: {},
        info: {},
        social: {},
        notification: {},
      },
      option: {
        general: {
          avatar64: '',
          avatar: '',
          name: '',
          surname: '',
          email: '',
          company: '',
          fiscalCode: '',
          vat: '',
          telephone: '',
          role: '',
          last_updated: '',
        },
        info: {
          bio: '',
          dob: '',
          cityBorn: '',
          country: '',
          streetAddress: '',
          numberAddress: '',
          website: '',
          telephone: '',
          phone: '',
        },
        social: {
          socialLinks: {
            twitter: '',
            facebook: '',
            google: '',
            linkedIn: '',
            instagram: '',
          },
        },
        notification: {
          receiveInvoice: false,
          receiveMessage: false,
          visitPatient: false,
          gdpr: false,
          marketing: false,
          thirdPart: false,
        },
      },
      option2: {
        general: {
          avatar64: '',
          avatar: require('@/assets/images/portrait/small/avatar-s-11.jpg'),
          name: 'John',
          surname: 'Doe',
          email: 'granger007@hogward.com',
          company: 'La Veterinaria',
          fiscalCode: 'QPFPDP74S29C030E',
          vat: '0023031DSESADASC',
          telephone: '+3995841202',
          role: 'Proprietario',
        },
        info: {
          bio: 'Mi piace vivere in campagna e cantare le canzoni di Gianni Celeste',
          dob: '1990-07-07',
          cityBorn: 'Catania',
          country: 'Italia',
          streetAddress: 'via etnea',
          numberAddress: '150',
          website: 'www.clinicalaveterinaria.it',
          telephone: '958 412 02',
          phone: '389 745 04',
        },
        social: {
          socialLinks: {
            twitter: 'https://www.twitter.com',
            facebook: 'https://www.facebook.com',
            google: 'https://www.google.com',
            linkedIn: 'https://www.linkedin.com',
            instagram: 'https://www.instagram.com',
          },
        },
        notification: {
          receiveInvoice: true,
          receiveMessage: true,
          visitPatient: false,
          gdpr: true,
          marketing: true,
          thirdPart: false,
        },
      },
      tabIndex: 0,
    }
  },
  /* computed: {
    localeNow() {
      return this.$i18n.locale
    },
  },
  mounted() {
    localize(this.localeNow)
  }, */
  computed: {
    /* preferencess: {
      get() {
        return JSON.parse(JSON.stringify(this.preferences))
      },
      set(value) {
        console.log('set', value)
      },
    }, */
  },
  beforeCreate() {
    // this.options = JSON.parse('{"general": {"avatar": "@/assets/images/portrait/small/avatar-s-11.jpg","name": "John","surname": "Doe","email": "granger007@hogward.com","company": "La Veterinaria", "fiscalCode": "QPFPDP74S29C030E"},"info": {"bio": "Mi piace vivere in campagna e cantare le canzoni di Gianni Celeste","dob": "1990-07-07","cityBorn": "Catania", "country": "Italia", "streetAddress": "via etnea","numberAddress": "150", "website": "www.clinicalaveterinaria.it", "telephone": 95841202,"phone": 38974504 },"social": { "socialLinks": {"twitter": "https://www.twitter.com","facebook": "https://www.facebook.com", "google": "https://www.google.com", "linkedIn": "https://www.linkedin.com", "instagram": "https://www.instagram.com"}},"notification": { "receiveInvoice": true,"receiveMessage": true,"visitPatient": false, "gdpr": true, "marketing": true, "thirdPart": false}}')
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
  mounted() {
    this.myProfile()
  },
  methods: {
    reset() {
      this.$store.dispatch('profile/getUserFromServer', this.$store.state.profile.generalReset)
      this.optionDiProva = { ...this.$store.state.profile.generalReset }
    },
    async updateGeneral(data) {
      console.log('updategeneral', data)
      await axios.post(process.env.VUE_APP_URL_API_SERVER, data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: String(JSON.parse(sessionStorage.getItem('activeAgency')).value),
            classe: 'Utente',
            metodo: 'setProfiloPersona',
            ACL: 'vat',
          },
        }).then(res => {
        if (res.data.esito === 'OK') {
          // this.optionDiProva = { ...res.data.persona[0], isChangedImage: false }
          this.optionDiProva = { ...res.data.persona[0] }
          this.optionDiProva.ruolo = this.optionDiProva.ruolo.split()
          this.$store.dispatch('profile/getUserFromServer', this.optionDiProva)
          // console.log(res)
          this.alertSuccess()
        }
      })
        .catch(e => e)
    },
    async updatePreferences(preferences) {
      const preferencess = JSON.stringify(preferences.map(el => ({ codice: el.codice, valore: el.valore })))
      // console.log('dentro update preferences', preferencess)
      await axios.post(process.env.VUE_APP_URL_API_SERVER,
        preferencess,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: String(JSON.parse(sessionStorage.getItem('activeAgency')).value),
            classe: 'Utente',
            metodo: 'setPreferenzePersona',
          },
        }).then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          this.$store.dispatch('profile/setPreferences', [...res.data.preferenze])
          this.alertSuccess()
        }
        // gestire errore tornando dal server i valori di default, quivalente al reset(valori attuali prima dell'update)
      })
        .catch(e => e)
    },
    async updateAddress(data) {
      // console.log('address updateAddress', data)
      await axios.post(process.env.VUE_APP_URL_API_SERVER, data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Persona',
            metodo: 'setRecapito',
            ACL: 'invoice-list',
          },
        }).then(res => {
        // console.log('edited', res)
        if (res.data.esito === 'OK') {
          // const addressEdited = { ...res.data.recapiti }
          // this.$store.state.profile.address.unshift(addressPop)
          // const index = this.$store.state.profile.address.findIndex(el => el.id === addressEdited.id)
          // this.$store.state.profile.address[index] = { ...addressEdited }
        }
        if (res.data.esito === 'OK') this.alertSuccess()
      }).catch(e => e)
    },
    updateContact() {
      // console.log()
    },
    resetPreferences() {
      // console.log('sono su reset preferenze', this.preferencesReset)
      // this.preferences.length = 0
      // this.preferences.push(...prova)
      // console.log('preferencesReset', this.preferencesReset)
      // console.log('prova', prova)
      // console.log('preferencesReset', this.preferencesReset)
    },
    async myProfile() {
      let roles = []
      await axios.post(process.env.VUE_APP_URL_API_SERVER, {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Utente',
            metodo: 'getProfiloPersona',
            ACL: '',
          },
        }).then(res => {
        console.log('getProfiloPersona', res)
        if (res.data.esito === 'OK') {
          this.optionDiProva = { ...res.data.persona[0] }
          this.userAddress = [...res.data.recapiti]
          this.optionDiProvaReset = { ...res.data.persona[0] }
          this.preferences = [...res.data.preferenze]
          roles = res.data.ruoli.map(el => (el.name))
          // console.log('roles', roles)
          this.optionDiProva.ruolo = roles
          /* res.data.response.original.preferenze.map(el => (
            {
              codice: el.codice, valore: { valore: el.valore, codice: el.codice }, testo: el.testo, disabled: el.codice === 'TERMINI_E_CONDIZIONI',
            }
          )) */
          this.options.general = { ...this.option.general }
          this.loading = false
        }
      })
        .catch(e => e)
      this.$store.dispatch('profile/setPreferences', this.preferences)
      // console.log('this.optionDiProva', this.optionDiProva)
      this.$store.dispatch('profile/getUserFromServer', this.optionDiProva)
      this.$store.dispatch('profile/setAddressFromServer', this.userAddress.slice(0, 20))
    },
    changeTabController(newTabIndex, prevTabIndex, bvEvent) {
      if (this.$refs['profile-setting-general'].somethingChangedBeforeChangeTab()) {
        // console.log('qui cambiato1')
        bvEvent.preventDefault()
        this.confirmShowAlert(newTabIndex, 'profile-setting-general')
        return true
      }
      if (this.$refs['profile-setting-address'].somethingChangedBeforeChangeTab()) {
        // console.log('qui cambiato2')
        bvEvent.preventDefault()
        this.confirmShowAlert(newTabIndex, 'profile-setting-address')
        return true
      }
      // console.log('psw change', this.$refs.['profile-setting-password'].somethingChangedBeforeChangeTab())
      if (this.$refs['profile-setting-password'].somethingChangedBeforeChangeTab()) {
        // console.log('qui cambiato3')
        bvEvent.preventDefault()
        this.confirmShowAlert(newTabIndex, 'profile-setting-password')
        return true
      }
      if (this.$refs['profile-setting-social'].somethingChangedBeforeChangeTab()) {
        // console.log('qui cambiato4')
        bvEvent.preventDefault()
        this.confirmShowAlert(newTabIndex, 'profile-setting-social')
        return true
      }
      if (this.$refs['profile-setting-notification'].somethingChangedBeforeChangeTab()) {
        // console.log('qui cambiato5')
        bvEvent.preventDefault()
        this.confirmShowAlert(newTabIndex, 'profile-setting-notification')
        return true
      }
      return false
    },
    async confirmShowAlert(indexToSendIfConfirm, refs) {
      this.$swal({
        // title: '<span class="font-weight-bolder">HTML <u>example</u></span>',
        icon: 'question',
        html:
          'Cambiando tabs perderai le modifhe effettuate, vuoi continuare?',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla',
        // confirmButtonAriaLabel: 'Thumbs up, great!',
        // cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn btn-primary m-2',
          cancelButton: 'btn btn-outline-danger m-2',
        },
        buttonsStyling: false,
      }).then(result => {
        // console.log(result.value !== undefined)
        if (result.value) {
          this.$refs[refs].resetForm()
          this.$refs['tabs-profile-setting'].currentTab = indexToSendIfConfirm
          return true
        }
        return true
      })
      return false
    },
    alertSuccess() {
      this.$swal({
        title: 'Salvataggio Effettuato Correttamente',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
